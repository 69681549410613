import axios, { AxiosInstance } from 'axios'
import { AxiosRes } from './index'
import { Translations } from '../hooks/useTranslation'

export const translationApi = (instance: AxiosInstance) => ({
    async getTranslations(culture: string, gameName: string): Promise<TranslationsRes> {
        const { data } = await axios.get<TranslationsRes>(
            `https://cryptogamesapi.yogames.win/api/Translation/translations?Culture=${culture}&GameName=${gameName}`,
        )
        return data
    },
})

interface TranslationsRes extends AxiosRes {
    result: Translations
}