import { AxiosInstance } from 'axios'
import { AxiosRes } from './index'

export const gameApi = (instance: AxiosInstance) => ({
    async getTopwins(gameName: string): Promise<TopwinsRes> {
        const { data } = await instance.get<TopwinsRes>('/api/Game/topwins?gameName=' + gameName)
        return data
    }
})

interface TopwinsRes extends AxiosRes {
    result: Topwin[]
}

export interface Topwin {
    additionalParams: {
        cashOutDate: any[]
        cashOutDuration: any[]
        index: any[]
        autoCashoutOdd: any[]
    }
    amount: number
    coinAmount: number
    currencyCode: string
    id: number
    odd: number
    playerExternalId: string
    roundId: number
    userId: number
    winAmount: number
    winCoinAmount: number
}
