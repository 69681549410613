import { createContext } from 'react'
import { OldGameInfo } from '../components/Game/Game'

const OldGameInfoContext = createContext<InitialStateContextType>([
    null,
    () => {}
])

export default OldGameInfoContext

type InitialStateContextType = [
    OldGameInfo | null,
    (value: ((prev: OldGameInfo | null) => OldGameInfo | null) | OldGameInfo | null) => void
]
