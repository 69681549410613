import { createContext } from 'react'
import { InitialState } from '../types/Messages'

const InitialStateContext = createContext<InitialStateContextType>([
  {} as InitialState,
  () => {}
])

export default InitialStateContext

type InitialStateContextType = [
  InitialState,
  (value: ((prev: InitialState) => InitialState) | InitialState) => void
]
