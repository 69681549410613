import axios from 'axios'
import { authApi } from './auth'
import { gameApi } from './game'
import { playerApi } from './player'
import { resultApi } from './result'
import { translationApi } from './translation'

interface ReturnApi {
    auth: ReturnType<typeof authApi>
    game: ReturnType<typeof gameApi>
    player: ReturnType<typeof playerApi>
    result: ReturnType<typeof resultApi>
    translation: ReturnType<typeof translationApi>
}

export interface AxiosRes {
    errorCode: null
    errorMessage: null
    status: number
}

const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_KEY ?? ''

const Api = (): ReturnApi => {
    const instance = axios.create({
        // withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL,
    })

    instance.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN_NAME)}`
        return config
    })

    return {
        auth: authApi(),
        game: gameApi(instance),
        player: playerApi(instance),
        result: resultApi(instance),
        translation: translationApi(instance),
    }
}

export default Api