import { AxiosInstance } from 'axios'
import {AxiosRes} from './index'

export const playerApi = (instance: AxiosInstance) => ({
    async getBalance(): Promise<PlayerBalanceRes> {
        const { data } = await instance.get<PlayerBalanceRes>('/api/Player/balance')
        return data
    },

    async getStatistics(gameName: string, skip: number, take: number): Promise<PlayerStatsRes> {
        const { data } =
            await instance.get<PlayerStatsRes>(`/api/Player/statistics?gameName=${gameName}&skip=${skip}&take=${take}`)
        return data
    }
})

interface PlayerBalanceRes extends AxiosRes {
    result: number
}

interface PlayerStatsRes extends AxiosRes {
    result: {
        count: number
        items: PlayerStatsItem[]
    }
}

export interface PlayerStatsItem  {
    additionalParams:{
        cashOutDate: Array<any>
        cashOutDuration: Array<any>
        index: Array<any>
        autoCashoutOdd: Array<any>
    }
    amount: number
    coinAmount: number
    currencyCode: string
    date: string
    freeBetId:null
    id: number
    odd: number
    resultHash: string
    resultKey: string
    resultSalt: string
    roundData: number
    roundId: number
    roundResult: string
    status: number
    winAmount: number
    winCoinAmount: number
}