import {FC, useEffect} from 'react'
import s from './Error.module.css'
import { svgIcons } from '../../assets/svgIcons'
import classNames from 'classnames'

const Error: FC<IProps> = ({ err, setErr }) => {
    useEffect(() => {
        if (err) {
            const timerId = setTimeout(() => setErr(''), 3000)
            return () => clearTimeout(timerId)
        }
    }, [err])

    return (
        <div
            className={classNames(s.wrapper, { [s.active]: !!err })}
            onClick={() => setErr('')}
        >
            {err}
            <span className={s.close}>{svgIcons.modal.close}</span>
        </div>
    )
}

export default Error

interface IProps {
    err: string
    setErr: (value: string) => void
}
