import en from '../i18n/en.json'
import { useContext } from 'react'
import GameConfigContext from '../context/GameConfigContext'

export type Translations = typeof en
export const useTranslation = () => {
    const [{ translations }] = useContext(GameConfigContext)

    return {
        t<Key extends keyof Translations>(key: Key, variables?: Record<string, string>) {
            let result = translations ? translations[key] : en[key]

            // if(variables) {
            //     Object.entries(variables).forEach(([key, value]) => {
            //         result = result.replace(`{{${key}}}`, value);
            //     })
            // }

            return result
        },
    };
};