import { FC, MouseEventHandler, ReactNode, useEffect, useState } from 'react'
import s from './Input.module.css'
import { svgIcons } from '../../assets/svgIcons'
import classNames from 'classnames'
import {useTranslation} from "../../hooks/useTranslation";

const Input: FC<IProps> = (props) => {
    const {
        label,
        calculatedValue,
        value,
        onChange,
        actions,
        currency,
        disabled,
        controls,
        step,
        min,
        max,
        precision,
        readOnly,
        className,
        inputBackground,
        type = 'text',
        canCopy,
        autoReplace,
        maxDecimalsPlace,
    } = props

    const { t } = useTranslation()

    const [info, setInfo] = useState('')

    const increase: MouseEventHandler<HTMLSpanElement> = e => {
        e.preventDefault()

        if (!step) return
        if (max && +value + step < max) return

        onChange(
            prev => (+prev + step)
                .toFixed(precision)
                .toString()
        )
    }

    const decrease: MouseEventHandler<HTMLSpanElement> = e => {
        e.preventDefault()

        if (!step) return
        if (min && +value - step < min) return

        onChange(
            prev => (+prev - step)
                .toFixed(precision)
                .toString()
        )
    }

    // Validation
    useEffect(() => {
        setInfo('')

        if (min && +value < min) {
            setInfo(t('MINVALUE') + ' ' + min)
        }

        if (max && +value > max) {
            setInfo(t('MAXVALUE') + ' ' + max)
        }


    }, [value])

    const changeValue = (newValue: string) => {
        let res = newValue

        // auto replace
        if (autoReplace?.length) {
            for (const [searchValue, replaceValue ] of autoReplace) {
                res = res.replace(searchValue, replaceValue)
            }
        }

        // check decimals place
        const newDecimalPLace = res.split('.')[1]
        const oldDecimalPLace = value.split('.')[1]

        if (
            maxDecimalsPlace
            && newDecimalPLace?.length > maxDecimalsPlace
            && newDecimalPLace?.length > oldDecimalPLace?.length
        ) {
            return
        }

        onChange(res)
    }

    const copy = async () => {
        await navigator.clipboard.writeText(value)
        setInfo(t('COPIED'))

        setTimeout(() => setInfo(''), 1000)
    }

    return (
        <div className={classNames(s.wrapper, className)}>
            <div className={s.head}>
                <label>{label}</label>
                <span>{calculatedValue}</span>
            </div>
            <div className={s.field}>
                <label className={s.input} style={{ backgroundColor: inputBackground }}>
                    {currency && <span>{currency}</span>}
                    <input
                        type={type}
                        value={value}
                        onChange={e => changeValue(e.target.value)}
                        disabled={disabled}
                        readOnly={readOnly}
                    />
                    {controls && (
                        <div className={s.controls}>
                            <span onClick={e => increase(e)}>
                                {svgIcons.inputControls.arrowUp}
                            </span>
                            <span onClick={e => decrease(e)}>
                                    {svgIcons.inputControls.arrowDown}
                            </span>
                        </div>
                    )}
                    {canCopy && (
                        <button onClick={() => copy()}>
                            {svgIcons.inputControls.copy}
                        </button>
                    )}
                </label>
                {actions?.map((action, i) => (
                    <button
                        key={'action-' + i}
                        disabled={readOnly || disabled}
                        className={s.btn}
                        onClick={action.action}
                    >
                        {action.text}
                    </button>
                ))}

                {info && <p className={s.info}>{info}</p>}
            </div>
        </div>
    )
}

export default Input

interface IProps {
    value: string
    onChange: (value: ((prev: string) => string) | string) => void
    label: string
    calculatedValue?: string
    actions?: Action[]
    currency?: ReactNode
    disabled?: boolean
    controls?: boolean
    step?: number
    min?: number
    max?: number
    precision?: number
    readOnly?: boolean
    className?: string
    type?: string
    inputBackground?: string
    canCopy?: boolean
    autoReplace?: Array<[RegExp, string]>
    maxDecimalsPlace?: number
}

interface Action {
    text: ReactNode
    action: () => void
}