import axios from 'axios'
import { AxiosRes } from './index'

const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_KEY ?? ''
const REFRESH_TOKEN_NAME = process.env.REACT_APP_REFRESH_TOKEN_KEY ?? ''
const AUTH_URL = process.env.REACT_APP_AUTH_URL ?? ''
const TIMESTAMP_NAME = process.env.REACT_APP_TOKEN_TIMESTAMP_KEY ?? ''
const ONE_TIME_TOKEN_NAME = process.env.REACT_APP_ONE_TIME_TOKEN_KEY ?? ''

const saveTokenData = (accessToken: string, refreshToken: string) => {
    localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
    localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken)
    localStorage.setItem(TIMESTAMP_NAME, Date.now().toString())
}

export const authApi = () => ({
    async login(oneTimeToken: string): Promise<{ accessToken: string }> {
        let accessToken: string = localStorage.getItem(ACCESS_TOKEN_NAME) ?? ''
        const timestamp = localStorage.getItem(TIMESTAMP_NAME) ?? ''
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME) ?? ''

        const oneTimeTokenOld = localStorage.getItem(ONE_TIME_TOKEN_NAME) ?? ''

        if (Date.now() - +timestamp > 30 && refreshToken) {
            try {
                const res = await this.refresh(refreshToken)
                accessToken = res.result.accessToken
            } catch (e) {
                //
            }
        }
        
        if (oneTimeToken !== oneTimeTokenOld) {
            const { data } =
                await axios.get<AuthRes>(`${AUTH_URL}/api/token/otp?oneTimeToken=${oneTimeToken}`)
            saveTokenData(data.result.accessToken, data.result.refreshToken)
            localStorage.setItem(ONE_TIME_TOKEN_NAME, oneTimeToken)
            accessToken = data.result.accessToken
        }

        return { accessToken }
    },

    async refresh(refreshToken: string): Promise<RefreshRes> {
        const { data } = await axios.post<RefreshRes>(
            `${AUTH_URL}/api/token/refresh?refreshToken=${refreshToken}`,
            {},
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN_NAME),
                }
            }
        )
        saveTokenData(data.result.accessToken, data.result.refreshToken)
        return data
    }
})

interface AuthRes extends AxiosRes {
    result: {
        accessToken: string
        refreshToken: string
    }
}

interface RefreshRes extends AxiosRes {
    result: {
        playerId: null
        playerIdExternalId: null
        currencyCode: null
        playerType: null
        expiresIn: number
        accessToken: string
        refreshToken: string
        userId: null
        requireChangePassword: boolean
        requiresVerification: boolean
        requireAuthenticatorSetup: boolean
    }
}