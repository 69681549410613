import { svgIcons } from '../../../../assets/svgIcons'
import classNames from 'classnames'
import s from './Odds.module.scss'
import {FC, useMemo} from 'react'
import { GameInfo } from '../../../../types/Game'

const Odds: FC<IProps> = ({ gameInfo }) => {
    const offset = useMemo(
        () => {
            if (!gameInfo) return 37

            const step = gameInfo.step < 2 ? 1 : gameInfo.step

            return 37 + 74 * (step - 1)
        },
        [gameInfo],
    )

    return (
        <div className={classNames(s.wrapper, { [s.active]: !!gameInfo } )}>
            {svgIcons.arrows.down}
            <div
                className={s.odds}
                style={{
                    transform: `translateX(-${offset}px)`
                }}
            >
                {/*<div className={s.overlay} />*/}
                {gameInfo?.oddsInfo.map((odd, i) => (
                    <div
                        key={i}
                        className={classNames(
                            s.odds__item,
                            {[s.current]: (gameInfo?.step === 0 && i === 0)|| gameInfo.odd === odd},
                            {[s.next]: gameInfo.odd < odd}
                        )}>
                        {svgIcons.oddsWrappers.fill}
                        <span>{`${odd ? odd + 'x' : ''}`}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Odds

interface IProps {
    gameInfo: GameInfo | null
}
