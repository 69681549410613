import {FC, MouseEventHandler} from 'react'
import { svgIcons } from '../../../../assets/svgIcons'
import s from './PickRandomTile.module.css'
import {useTranslation} from "../../../../hooks/useTranslation";

const PickRandomTile: FC<IProps> = ({ onClick, disabled }) => {
    const { t } = useTranslation()

    return (
        <button className={s.pickRandom} onClick={onClick} disabled={disabled}>
            {svgIcons.random}
            {t('PICKRANDOM')}
        </button>
    )
}

export default PickRandomTile

interface IProps {
    onClick: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
}