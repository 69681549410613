import React, {FC, ReactNode} from 'react'
import s from './Checkbox.module.css'
import classNames from 'classnames'

const Checkbox: FC<IProps> = ({
    checked,
    onChange,
    children,
    className,
    clickSoundExcept = false,
    disabled,
}) => {
    return (
        <label className={classNames(s.checkbox, className)} data-click-except={clickSoundExcept}>
            <input
                type="checkbox"
                checked={checked}
                onChange={e => onChange(e.target.checked)}
                data-click-except={clickSoundExcept}
                disabled={disabled}
            />
            <span className={s.control} data-click-except={clickSoundExcept}/>
            {children}
        </label>
    )
}

export default Checkbox

interface IProps {
    checked: boolean
    onChange: (value: boolean) => void
    children: ReactNode
    className?: string
    clickSoundExcept?: boolean
    disabled?: boolean
}