import { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import Game from '../components/Game/Game'
import { useSounds } from '../utils/useSounds'

const HomePage = () => {
    const { clickSound } = useSounds()

    useEffect(() => {
        const listener = (e: MouseEvent) => {
            if (e.target instanceof HTMLElement) {
                !e.target?.dataset.clickExcept ? clickSound() : null
            }
        }
        document?.addEventListener('click', listener)

        return () => document.removeEventListener('click', listener)
    }, [clickSound])

    return (
        <Layout>
            <Game />
        </Layout>
    )
}

export default HomePage