import { createContext } from 'react'

const PopupsOpenContext = createContext<PopupsContextType>([{} as IPopups, () => {}])

export default PopupsOpenContext

export interface IPopups {
    provablyFairness: boolean
    rules: boolean
}

type PopupsContextType = [
    IPopups,
    (value: ((prev: IPopups) => IPopups) | IPopups) => void,
]