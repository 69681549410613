import { useContext } from 'react'
import GameConfigContext from '../context/GameConfigContext'
import useSound from 'use-sound'
import { PlayFunction } from 'use-sound/dist/types'

import autoplaySound from '../assets/sounds/autoplay.mp3'
import betSound from '../assets/sounds/bet.mp3'
import cashoutSound from '../assets/sounds/cashout.mp3'
import clickSound from '../assets/sounds/click.mp3'
import crashSound from '../assets/sounds/crash.mp3'
import winSound from '../assets/sounds/win.mp3'

export const useSounds = () => {
    const [{ isMute }] = useContext(GameConfigContext)

    const [autoplay] = useSound(autoplaySound)
    const [bet] = useSound(betSound)
    const [cashout] = useSound(cashoutSound)
    const [click] = useSound(clickSound)
    const [crash] = useSound(crashSound)
    const [win] = useSound(winSound)

    const checkIsMute = (f: PlayFunction) =>
        isMute ? () => {} : f

    return {
        autoplaySound: checkIsMute(autoplay),
        betSound: checkIsMute(bet),
        cashoutSound: checkIsMute(cashout),
        clickSound: checkIsMute(click),
        crashSound: checkIsMute(crash),
        winSound: checkIsMute(win),
    }
}