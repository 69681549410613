import { FC, ReactNode } from 'react'
import s from './Select.module.css'
import classNames from 'classnames'
import { svgIcons } from '../../assets/svgIcons'

const Select: FC<IProps> = ({
    className,
    label,
    children,
    value,
    onChange,
    disabled,
}) => {
    return (
        <div className={classNames(s.wrapper, { [s.disabled]: disabled }, className)}>
            <p className={s.label}>{label}</p>
            <div className={s.select}>
                <select
                    value={value}
                    onChange={e => onChange(e.target.value)}
                >
                    {children}
                </select>
                {svgIcons.arrows.down}
            </div>
        </div>
    )
}

export default Select

interface IProps {
    className?: string
    label: string
    children: ReactNode
    value: string
    onChange: (value: string) => void
    disabled: boolean
}
