import {FC, useContext} from 'react'
import classNames from 'classnames'
import s from './Win.module.css'
import {checkCurrencyCode} from '../../../../utils/checkCurrencyCode'
import winImg from '../../../../assets/win.png'
import InitialStateContext from '../../../../context/InitialStateContext'
import {useTranslation} from "../../../../hooks/useTranslation";

const Win: FC<IProps> = ({ win }) => {
    const { t } = useTranslation()
    const [initState] = useContext(InitialStateContext)

    return (
        <div className={classNames(s.win, win && s.win_active)}>
            <img src={winImg} alt="coins"/>
            <p>{t('WON')} <span>{win} {checkCurrencyCode(initState?.currencyCode)}</span></p>
        </div>
    )
}

export default Win

interface IProps {
    win: number
}
