import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import s from './Game.module.css'
import PlayingField from './PlayingField/PlayingField'
import Control from './Control/Control'
import { useSearchParams } from 'react-router-dom'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import {
    CashoutResult,
    ChangeResult,
    DoActionResult,
    InitialState,
    OldResultHashAndKey,
    SpinResult
} from '../../types/Messages'
import InitialStateContext from '../../context/InitialStateContext'
import BalanceContext from '../../context/BalanceContext'
import GameConfigContext from '../../context/GameConfigContext'
import Api from '../../api'
import Popup from '../Popup/Popup'
import { useSounds } from '../../utils/useSounds'
import PopupsOpenContext, { IPopups } from '../../context/PopupsContext'
import ProvablyFairnessModal from './ProvablyFairnessPopup/ProvablyFairnessPopup'
import RulesPopup from './RulesPopup/RulesPopup'
import { GameInfo } from '../../types/Game'
import ErrorContext from '../../context/ErrorContext'
import yo from '../../assets/yo.png'
import OldGameInfoContext from '../../context/OldGameInfoContext'
import {useTranslation} from "../../hooks/useTranslation";

const Game = () => {
    const { t } = useTranslation()
    const [, setErr] = useContext(ErrorContext)

    const { betSound, cashoutSound, winSound, autoplaySound, crashSound} = useSounds()

    const [params] = useSearchParams()
    const partnerId = params.get('PartnerId')
    const gameName = params.get('GameName')
    const oneTimeToken = params.get('OneTimeToken')
    const mode = params.get('Mode')

    const [connection, setConnection] = useState<HubConnection | null>(null)

    const [balance, setBalance] = useContext(BalanceContext)
    const [initialState, setInitialState] = useContext(InitialStateContext)

    const [{ isMute }] = useContext(GameConfigContext)
    const [popupOpen, setPopupOpen] = useContext(PopupsOpenContext)

    const openPopup = useCallback(
        (popup: keyof IPopups, value: boolean) => {
            setPopupOpen(prev => ({
                ...prev,
                [popup]: value,
            }))
        },
        [popupOpen]
    )

    const [oldGameInfo, setOldGameInfo] = useContext(OldGameInfoContext)

    // Control
    const [amount, setAmount] = useState<string>('0')
    const [mines, setMines] = useState('3')

    const [isAutobet, setIsAutobet] = useState(false)
    const [selectedAutoIndices, setSelectedAutoIndices] = useState<
        number[]
    >(Array(25).fill(0)) // [0, 1, 0] // 1 - selected
    const [isAutobetRunning, setIsAutobetRunning] = useState(false)

    useEffect(() => {
        if (!isAutobet) {
            setSelectedAutoIndices(Array(25).fill(0))
        } else {
            autoplaySound()
        }
        setIsAutobetRunning(false)
    }, [isAutobet])

    const [numberOfRounds, setNumberOfRounds] = useState('10')

    const [gameInfo, setGameInfo] = useState<GameInfo | null>(null)
    const [win, setWin] = useState(0)

    const handleInitialState = (data: InitialState) => {
        setBalance(data.balance)
        setInitialState(data)

        if (data.pendingSpinInfo) {
            const { pendingSpinInfo, oddsInfo } = data

            setGameInfo({
                amount: pendingSpinInfo.amount,
                bombCount: pendingSpinInfo.spinData.bombCount,
                odd: pendingSpinInfo.odd,
                oddsInfo: oddsInfo.map(odd => odd.value),
                mineFieldStates: pendingSpinInfo.spinData.mineFieldStates,
                step: pendingSpinInfo.spinData.step,
            })
        } else {
            const type = localStorage.getItem(process.env.REACT_APP_MINES_KEY ?? '') ?? mines
            changeResult(+type)
        }
    }

    const handleSpinResult = (data: SpinResult, mines: number) => {
        if (!data.result) return

        setBalance(data.balance)
        setGameInfo({
            amount: data.amount,
            bombCount: mines, // todo
            odd: data.odd,
            mineFieldStates: Array(25).fill(0),
            oddsInfo: data.result?.odds ?? [],
            step: 0,
        })
    }

    const handleChangeResult = (data: ChangeResult) => {

    }

    const crashTimerRef = useRef<NodeJS.Timeout | null>(null)
    const autobetIndiciesRef = useRef<number[]>([])

    const stopAutobet = () => {
        setIsAutobet(false)
        setSelectedAutoIndices(Array(25).fill(0))
        setIsAutobetRunning(false)
    }

    const [isDoAction, setIsDoAction] = useState(false)
    const [isGameFinished, setIsGameFinished] = useState(false)

    useEffect(() => {
        if (!isGameFinished && isAutobet) {
            +numberOfRounds > 0 ? runAutoBet() : stopAutobet()
        }
    }, [isGameFinished])

    const handleDoActionResult = (data: DoActionResult) => {
        if (crashTimerRef.current) {
            clearTimeout(crashTimerRef.current)
        }

        crashTimerRef.current = null

        setGameInfo(prev => prev
            ? data.spinData
                ? {
                    ...prev,
                    amount: data.amount,
                    bombCount: data.spinData.bombCount,
                    mineFieldStates: data.spinData.mineFieldStates,
                    step: data.spinData.step,
                    odd: data.odd,
                }
                : null
            : null
        )

        if (data.status === 0) {
            setIsGameFinished(true)
            crashSound()
            crashTimerRef.current = setTimeout(() => {
                setGameInfo(null)
                setIsGameFinished(false)
            }, 3000)

            if (data.oldResultHashAndKey) {
                setOldGameInfo({ ...data.oldResultHashAndKey, odd: 0 })
            }
        } else if (isAutobet && autobetIndiciesRef.current.length) {
            const i = autobetIndiciesRef.current.shift() as number
            doAction(i)
        } else if (data.status !== 1 && isAutobet && !autobetIndiciesRef.current.length) {
            cashout()
        }

        if (data.status === 1) {
            handleCashoutResult({
                oldResultHashAndKey: data.oldResultHashAndKey as OldResultHashAndKey,
                odd: data.odd,
                winAmount: data.winAmount,
                newHash: data.newHash,
                spinId: data.spinId,
                responseCode: data.responseCode,
                winCoinAmount: data.winCoinAmount,
            })
        }

        setIsDoAction(false)
    }

    const handleCashoutResult = (data: CashoutResult ) => {
        setWin(data.winAmount)

        if (data.oldResultHashAndKey) {
            setOldGameInfo({ ...data.oldResultHashAndKey, odd: data.odd })
        }

        if (data.winAmount) {
            winSound()
        }
    }

    useEffect(() => {
        if (!win) return

        setBalance(prev => prev + win)
        setIsGameFinished(true)

        const timerId = setTimeout(() => {
            setGameInfo(null)
            setIsGameFinished(false)
            setWin(0)
        }, 3000)
        return () => clearTimeout(timerId)
    }, [win])

    const spin = async (amount: number, mines: number) => {
        if (!connection) return

        if (amount > balance) {
            setErr(t('NOBALANCE'))
            return
        }

        if (amount > initialState.limits.maxBet || amount < initialState.limits.minBet) {
            return
        }

        betSound()

        setBalance(prev => prev - amount)

        const data = {
            spinData: mines,
            amount,
        }

        await connection.invoke('Spin', data)
    }

    const changeResult = async (mines: number) => {
        if (!connection) return

        await connection.invoke('ChangeResult', { type: mines })
    }

    const doAction = async (index: number) => {
        if (!connection || (!gameInfo && !isAutobet)) return

        setIsDoAction(true)

        await connection.invoke('DoAction', { ActionData: [index] })
    }

    const cashout = async () => {
        if (!connection) return

        cashoutSound()
        await connection.invoke('Cashout')
    }

    useEffect(() => {
        const connect = async () => {
            let token: string | null = null

            if (oneTimeToken) {
                const { accessToken } = await Api().auth.login(oneTimeToken)
                token = accessToken
            }

            let wsUrl = process.env.REACT_APP_WS as string

            if (token) {
                wsUrl = wsUrl + '?access_token=' + token
            }

            const c = new HubConnectionBuilder()
                .withUrl(wsUrl)
                .withAutomaticReconnect()
                .build()
            setConnection(c)
        }
        connect()
    }, [])

    useEffect(() => {
        if (!connection) return

        const runConnection = async () => {
            connection.on('InitialStateResult', handleInitialState)
            connection.on('SpinResult', handleSpinResult)
            connection.on('ChangeResult', handleChangeResult)

            await connection.stop()
            await connection.start()

            let initStateDto: Object = { partnerId, gameName }

            if (mode === 'demo') {
                initStateDto = { ...initStateDto, isDemo: true }
            }

            await connection.invoke("InitialState", initStateDto )
        }
        runConnection()
    }, [connection])

    useEffect(() => {
        if (!connection) return

        connection.off('DoActionResult')
        connection.off('CashoutResult')

        connection.on('DoActionResult', handleDoActionResult)
        connection.on('CashoutResult', handleCashoutResult)
    }, [connection, isMute, isAutobet, numberOfRounds])

    const runAutoBet = async () => {
        if (!isAutobet || +numberOfRounds < 1) return

        if (+amount > balance) {
            setErr(t('NOBALANCE'))
            setIsAutobet(false)
            return
        }

        setIsAutobetRunning(true)

        setNumberOfRounds(prev => (+prev - 1).toString())

        autobetIndiciesRef.current = selectedAutoIndices
            .map((value, i) =>
                value === 1 ? i : undefined
            )
            .filter(num => num !== undefined) as number[]

        await spin(+amount, +mines)
        const firstIndex = autobetIndiciesRef.current.shift() as number
        doAction(firstIndex)
    }

    if (!Object.keys(initialState).length) {
        return (
            <div className="h-screen flex items-center justify-center">
                <img src={yo} alt="Yo" className="w-[200px]"/>
            </div>
        )
    }

    return (
        <div className={s.game}>
            <PlayingField
                gameInfo={gameInfo}
                doAction={doAction}
                win={win}
                isAutobet={isAutobet}
                selectedAutoIndices={selectedAutoIndices}
                setSelectedAutoIndices={setSelectedAutoIndices}
                mines={+mines}
                isDoAction={isDoAction}
                isGameFinished={isGameFinished}
            />
            <Control
                gameInfo={gameInfo}
                amount={amount}
                setAmount={setAmount}
                mines={mines}
                setMines={setMines}
                isAutobet={isAutobet}
                setIsAutobet={setIsAutobet}
                numberOfRounds={numberOfRounds}
                setNumberOfRounds={setNumberOfRounds}
                spin={spin}
                changeResult={changeResult}
                cashout={cashout}
                win={win}
                selectedAutoIndices={selectedAutoIndices}
                runAutobet={runAutoBet}
                isAutobetRunning={isAutobetRunning}
            />

            {oldGameInfo && (
                <Popup
                    active={popupOpen.provablyFairness}
                    setActive={value => openPopup('provablyFairness', value)}
                >
                    <ProvablyFairnessModal lastRound={oldGameInfo} />
                </Popup>
            )}

            <Popup
                active={popupOpen.rules}
                setActive={value => openPopup('rules', value)}
            >
                <RulesPopup />
            </Popup>
        </div>
    )
}

export default Game

export interface OldGameInfo extends OldResultHashAndKey {
    odd: number
}