import classNames from 'classnames'
import s from './Popup.module.css'
import { FC, ReactNode } from 'react'
import { svgIcons } from '../../assets/svgIcons'

const Popup: FC<IProps> = ({ children, active, setActive }) => {
    return (
        <div
            className={classNames(s.overflow, active && s.active)}
            onClick={() => setActive(false)}
        >
            <div className={s.popup} onClick={e => e.stopPropagation()}>
                <button
                    onClick={() => setActive(false)}
                    className={s.close}
                >
                    {svgIcons.modal.close}
                </button>
                {children}
            </div>
        </div>
    )
}

export default Popup

interface IProps {
    children: ReactNode
    active: boolean
    setActive: (value: boolean) => void
}