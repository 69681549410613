import { FC, useCallback, useContext } from 'react'
import Odds from './Odds/Odds'
import Win from './Win/Win'
import PickRandomTile from './PickRandomTile/PickRandomTile'
import classNames from 'classnames'
import s from './PlayingField.module.scss'
import { GameInfo } from '../../../types/Game'
import { randomIndex } from '../../../utils/randomIndex'
import ErrorContext from '../../../context/ErrorContext'
import {useTranslation} from "../../../hooks/useTranslation";

const PlayingField: FC<IProps> = ({
    gameInfo,
    doAction,
    win,
    isAutobet,
    selectedAutoIndices,
    setSelectedAutoIndices,
    mines,
    isDoAction,
    isGameFinished,
}) => {
    const { t } = useTranslation()
    const [, setErr] = useContext(ErrorContext)

    const toggleSelectAutoIndicies = (i?: number) => {
        const index = i ?? randomIndex(selectedAutoIndices)
        const newArr = [...selectedAutoIndices]

        if (i && selectedAutoIndices[i] === 1) {
            newArr.splice(i, 1, 0)
            setSelectedAutoIndices(newArr)
            return
        }

        if (25 - mines - selectedAutoIndices.filter(i => i === 1).length <= 0) {
            return
        }

        newArr.splice(index, 1, 1)
        setSelectedAutoIndices(newArr)
    }

    const handleClickField = useCallback((game: GameInfo | null, isAutobet: boolean) => {
        if (!game && !isAutobet) {
            setErr(t('PRESSBET'))
        }
    }, [])

    const onClickButton = (i: number) => {
        if (!gameInfo && !isAutobet ) {
            return
        }

        isAutobet ? toggleSelectAutoIndicies(i) : doAction(i)
    }


    return (
        <div className={s.wrapper} onClick={() => handleClickField(gameInfo, isAutobet)}>
            <Odds gameInfo={gameInfo} />
            <div className={s.grid}>
                {Array(25)
                    .fill('')
                    .map((_, i) => (
                        <button
                            key={i}
                            className={classNames(
                                {[s.selected]: (gameInfo?.mineFieldStates[i] ?? 0) > 0},
                                {[s.selected]: isAutobet && selectedAutoIndices[i] === 1},
                            )}
                            onClick={() => onClickButton(i)}
                            disabled={
                                gameInfo?.mineFieldStates.some(i => i > 1) ||
                                (isAutobet && !!gameInfo) ||
                                gameInfo?.mineFieldStates[i] === 1 ||
                                isDoAction
                            }
                        >
                            {gameInfo && <div className={s.click}/>}
                            <div className={classNames(
                                s.bg,
                                {[s.coin]: gameInfo?.mineFieldStates[i] === 1},
                                {[s.bomb]: gameInfo?.mineFieldStates[i] === 2},
                                {[s.crash]: gameInfo?.mineFieldStates[i] === 3},
                            )} />
                        </button>
                    ))}
            </div>
            {gameInfo && !isAutobet && (
                <PickRandomTile
                    onClick={() => doAction(randomIndex(gameInfo?.mineFieldStates))}
                    disabled={isDoAction || isGameFinished}
                />
            )}
            {isAutobet && !gameInfo && (
                <PickRandomTile
                    onClick={() => toggleSelectAutoIndicies()}
                />
            )}
            <Win win={win} />
        </div>
    )
}

export default PlayingField

interface IProps {
    gameInfo: GameInfo | null
    doAction: (index: number) => void
    win: number
    isAutobet: boolean
    selectedAutoIndices: number[]
    setSelectedAutoIndices: (value: ((prev: number[]) => number[]) | number[]) => void
    mines: number
    isDoAction: boolean
    isGameFinished: boolean
}