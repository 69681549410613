import React, {FC, MouseEventHandler, MutableRefObject, ReactNode, useRef} from 'react'
import s from './Button.module.css'
import classNames from "classnames";

const Button: FC<IProps> = ({
                                children,
                                className,
                                onClick,
                                isSecondary,
                                disabled,
                                componentRef,
                                clickSoundExcept = false
                            }) => {
    return (
        <button
            className={classNames(
                s.btn,
                isSecondary && s.btn_secondary,
                className ?? ''
            )}
            onClick={onClick}
            disabled={!!disabled}
            ref={componentRef}
            data-click-except={clickSoundExcept}
        >
            {children}
        </button>
    )
}

export default Button

interface IProps {
    children: ReactNode
    className?: string
    onClick: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    isSecondary?: boolean
    componentRef?: MutableRefObject<HTMLButtonElement>
    clickSoundExcept?: boolean
}