import { AxiosInstance } from 'axios'
import { AxiosRes } from './index'

export const resultApi = (instance: AxiosInstance) => ({
    async getResult(key: string, salt: string): Promise<ResultRes> {
        const { data } = await instance.get<ResultRes>(`/api/Result/hash?key=${key}&salt=${salt}`)
        return data
    },
})

interface ResultRes extends AxiosRes {
    result: string
}