export function randomIndex(field: number[] | undefined): number {
    if (!field) return 0

    const freeIndices: number[] = []

    field.map((value, i) => {
        if (value === 0) {
            freeIndices.push(i)
        }
    })

    const randomIndex = Math.floor(Math.random() * freeIndices.length)

    return freeIndices[randomIndex]
}